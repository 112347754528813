import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Box from "@mui/material/Box";
import { styled } from "@mui/material";

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",

    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    marginTop: "50px",
    paddingLeft: "40px",
    paddingRight: "40px",
    color: "white",

    fontWeight: "400",
    fontSize: "32px",
    fontFamily: "handa",
    "&:hover": {
      color: "white",
    },
    "&.Mui-selected": {
      color: "#000",
      background: "#fdf133",
      border: "3px solid #ff7200",
      opacity: 1,
      borderRadius: "89px",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#FFFFFF",
    },
  })
);

export default function StyledTabs({ btnName, setTabsValue, tabsValue }) {
  const handleChange = (event, newValue) => {
    setTabsValue(newValue);
  };
  return (
    <>
      <Box
        sx={{
          // border: "2px solid #D2D4D6",
          borderRadius: "89px",
          width: "fit-content",
          p: 1,
          background: "black",
        }}
      >
        <Tabs
          value={tabsValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant={"scrollable"}
          scrollButtons={"auto"}
          sx={{
            "& .MuiTabs-indicator": {
              display: "none",
            },
          }}
        >
          {btnName?.map((name, index) => (
            <AntTab key={name} label={name} value={index} />
          ))}
        </Tabs>
      </Box>
    </>
  );
}
