import React, { useState } from "react";
import { Box, Container, Slider, TextField } from "@mui/material";
import moment from "moment";
import { toast } from "react-toastify";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
// import TextField from "./TextField";
import { useContext } from "react";
import { AppContext, url } from "../utils";
import { useTokenLockerContract } from "../ConnectivityAssets/hooks";
import { Contract } from "ethers";
import standerdTokenABI from "../ConnectivityAssets/standerdTokenAbi.json";
import Loader from "../Components/SmallComponents/loading";
import { formatUnits, parseUnits } from "@ethersproject/units";
import { tokenLocker } from "../ConnectivityAssets/environment";
import { ToastNotify } from "../Components/SmallComponents/AppComponents";
import { useEffect } from "react";
import { styled } from "@mui/styles";
// import Services from "../Services/Services";
const TooltipPlatform = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: "100px",
    fontSize: "20px",
    border: "1px solid #dadde9",
  },
}));

function CreateLock() {
  const [tokenAddress, setTokenAddress] = useState("");
  const [lockedAmount, setlockedAmount] = useState();
  const [lockedTime, setlockedTime] = useState("");
  const [tokenContract, settokenContract] = useState("");
  const [tokenDecimal, settokenDecimal] = useState("");
  const [loading, setloading] = useState(false);
  const [tokenSymbol, settokenSymbol] = useState("");
  const [tokenName, settokenName] = useState("");
  const [totalSupply, settotalSupply] = useState([]);
  const [tokenBalance, settokenBalance] = useState(0);
  const [platFormFee, setplatFormFee] = useState(0);
  const [tokenPercentage, settokenPercentage] = useState(0);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  ///////// COnnectivity /////////////

  const { account, signer } = useContext(AppContext);
  const tokenLockerContract = useTokenLockerContract(signer);

  const tokenAddressHandler = async (address) => {
    setloading(true);
    setTokenAddress(address);
    if (!account) {
      setAlertState({
        open: true,
        message: "Please connect your wallet first.",
        severity: "error",
      });
    }
    if (address?.length == 42 && account) {
      setloading(true);
      try {
        const TokenContract = await new Contract(
          address,
          standerdTokenABI,
          signer
        );
        const decimals = await TokenContract.decimals();
        const symbol = await TokenContract.symbol();
        const name = await TokenContract.name();
        const supply = await TokenContract.totalSupply();
        const balance = await TokenContract.balanceOf(account);
        settokenBalance(formatUnits(balance.toString(), +decimals));
        settokenDecimal(decimals);
        settokenSymbol(symbol);
        settokenName(name);
        settokenContract(TokenContract);
        settotalSupply(supply);
        setloading(false);
      } catch (error) {
        if (error?.data?.message) {
          toast.error(error?.data?.message);
        } else {
          toast.error(error?.message);
        }
        setloading(false);
      }
    } else if (address?.length > 42) {
      toast.error("invalid token address");
      setloading(false);
    } else {
      setloading(false);
    }
  };

  const lockHandler = async () => {
    if (!account) {
      setAlertState({
        open: true,
        message: "Error! Please connect your wallet.",
        severity: "error",
      });
    } else if (!tokenAddress) {
      setAlertState({
        open: true,
        message: "Error! Please enter token address",
        severity: "error",
      });
    } else if (!lockedAmount) {
      setAlertState({
        open: true,
        message: "Error! Please enter amount",
        severity: "error",
      });
    } else if (lockedAmount <= 0 || isNaN(lockedAmount)) {
      setAlertState({
        open: true,
        message: "Error! Please enter vailed amount",
        severity: "error",
      });
    } else if (!lockedTime) {
      setAlertState({
        open: true,
        message: "Error! Please select lockedTime",
        severity: "error",
      });
    } else {
      setloading(true);
      try {
        setloading(true);
        let fee = await tokenLockerContract.platformFee();
        console.log(fee.toString(), "fee");
        const txApprove = await tokenContract.approve(
          tokenLocker,
          parseUnits(lockedAmount.toString(), +tokenDecimal).toString()
        );
        await txApprove.wait();
        const tx = await tokenLockerContract.lock(
          parseUnits(lockedAmount.toString(), +tokenDecimal).toString(),
          lockedTime.toString(),
          tokenAddress,
          {
            value: fee.toString(),
          }
        );
        await tx.wait();
        setAlertState({
          open: true,
          message: `Succeccfully Locked!`,
          severity: "success",
        });
        setloading(false);
      } catch (error) {
        setloading(false);
        if (error?.data?.message) {
          setAlertState({
            open: true,
            message: error?.data?.message,
            severity: "error",
          });
        } else if (error?.reason) {
          setAlertState({
            open: true,
            message: error?.reason,
            severity: "error",
          });
        } else {
          setAlertState({
            open: true,
            message: error?.message,
            severity: "error",
          });
        }
      }
    }
  };
  const handleSliderChange = (event, newValue) => {
    settokenPercentage(newValue);
  };
  useEffect(() => {
    const init = async () => {
      try {
        let amount = (+tokenBalance * +tokenPercentage) / 100;
        amount = parseFloat(amount).toFixed(0);
        setlockedAmount(amount);

        let fee = await tokenLockerContract.platformFee();
        setplatFormFee(+formatUnits(fee.toString()));
      } catch (error) { }
    };
    if (tokenLockerContract) {
      init();
    }
  }, [tokenLockerContract, tokenPercentage]);
  const amountCalculator = async (per) => {
    if (tokenBalance === 0) {
      setAlertState({
        open: true,
        message: "You have 0 token",
        severity: "error",
      });
    } else {
      settokenPercentage(per);
      let amount = (+tokenBalance * +per) / 100;
      amount = parseFloat(amount).toFixed(0);
      setlockedAmount(amount);
    }
  };
  return (
    <Box mt={2}>
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Loader loading={loading} />
      <Container maxWidth="md">
        <Box
          borderRadius="10px"
          p={3}
          display="block"
          alignItems="start"
          flexDirection="column"
          boxShadow="#ff7200 0px 0px 10px 1px"
          sx={{
            background: '#fdf133'
            // background:
            // "linear-gradient(83.55deg,#3d15a8 14.95%,#dee3e4 84.15% )",
          }}
        >
          <Box
            fontSize={{ xs: "14px", sm: "26px" }}
            borderBottom="2px solid #ff7200"
            color="#000"
            width="100%"
            // py={2}
            textAlign="center"
          >
            COMING SOON!
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default CreateLock;
