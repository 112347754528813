import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";

import Tokens from "./LockTokens";
import LockTokens from "./MyLockTokens";
import { Container, InputBase, Typography, styled } from "@mui/material";
// import { BsSearch } from "react-icons/bs";
import { TabList, TabPanel } from "@mui/lab";

const Search = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "transparent",
  border: "1px solid #fff",
  "&:hover": {
    backgroundColor: "#000000",
    border: "1px solid #FED256",
  },

  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  width: "20%",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(1)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
}));

export default function AllTokens() {
  const [value, setValue] = useState("1");
  const [search, setsearch] = useState("");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box py={5}>
      <Container maxWidth="md">
        {" "}
        {/* <Box width="100%">
          <Search sx={{ width: "100%" }}>
            <StyledInputBase
              sx={{ width: "80%" }}
              placeholder="Paste your address"
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => setsearch(e.target.value)}
            />
           
          </Search>
        </Box> */}
        <Box sx={{ width: "100%", typography: "body1" }}>
          {/* <TabContext value={value}>
            <Box
              style={{
                borderBottom: 1,
                borderColor: "divider",
                fontFamily: "handa",
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  style={{ fontFamily: "handa", color: "#fff" }}
                  label="All "
                  value="1"
                />
                <Tab
                  style={{ fontFamily: "handa" }}
                  label="My Lock "
                  value="2"
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Tokens />
            </TabPanel>
            <TabPanel value="2">
              <LockTokens />
            </TabPanel>
          </TabContext> */}
          <Typography
            mb={2}
            sx={{
              textAlign: "center",
              fontSize: "28px",
              fontFamily: "handa",
              fontWeight: "700",
              color: "#fff",
            }}
          >
            My Lock
          </Typography>
          <LockTokens />
        </Box>
      </Container>
    </Box>
  );
}
