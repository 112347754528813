import React, { useContext, useState } from "react";
import { Box, Container, useMediaQuery } from "@mui/material";
import { AppContext } from "../utils";
import logo from "../Images/logo2.png";

export default function Navbar() {
  const { account, connect, disconnect, signer } = useContext(AppContext);
  const matches = useMediaQuery("(max-width:960px)");

  return (
    <Box
      sx={{
        // background: "#09121D",
      }}
    >
      <Container maxWidth="xl">
        <Box
          py={2}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box display="flex" alignItems="center">
            
          </Box>

          {account ? (
            <Box
              // mt={2}
              width={matches ? "150px" : "221px"}
              height={matches ? "40px" : "52px"}
              borderRadius="8px"
              sx={{
                cursor: "pointer",
                background: "#fdf133",
                boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px",
                border: "3px solid #ff7200",
                borderRadius: "100px",
              }}
              fontFamily="handa"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="#000"
              fontWeight="700"
              fontSize={matches ? "14px" : "16px"}
              onClick={() => disconnect()}
              style={{ zIndex: 1 }}
            >
              {account.slice(0, 4) + "..." + account.slice(-4)}
            </Box>
          ) : (
            <Box
              zIndex={1}
              // mt={2}
              style={{
                cursor: "pointer",
                background: " #fdf133",
                boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px",
                border: "3px solid #ff7200",
                borderRadius: "100px",
              }}
              width={matches ? "150px" : "221px"}
              height={matches ? "40px" : "52px"}
              fontWeight="700"
              borderRadius="8px"
              fontSize={matches ? "12px" : "16px"}
              fontFamily="handa"
              color="#000"
              display="flex"
              justifyContent="center"
              alignItems="center"
              onClick={() => connect()}
            >
              Connect Wallet
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
}
