import { Box, Container, Typography } from "@mui/material";
import React from "react";
import logo from "../Images/logo2.png";
export default function Footer() {
  return (
    <>
      <Box
        py={2}
        sx={{
          // background: "#09121D",
        }}
      >
        <Container maxWidth="xl">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              sx={{
                fontFamily: "handa",
                fontSize: { xs: "10px", md: "14px" },
                color: "#fff",
              }}
            >
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
}
