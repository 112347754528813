import "./App.css";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import HeroSection from "./Pages/HeroSection";
// import Socials from "./Pages/Socials";

function App() {
  return (
    <div>
      <Navbar />
      <HeroSection />
      {/* <Socials /> */}
      <Footer />
    </div>
  );
}

export default App;
