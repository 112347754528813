import { Box, Container } from "@mui/material";
import React from "react";
import StyledTabs from "./StyledTabs";
import { useState } from "react";
import CreateLock from "./CreateLock";
import AllTokens from "./AllTokens/AllTokens";

const btnName = ["ERC404", "ERC20", "SOL", "BNB"];
export default function HeroSection() {
  const [tabsValue, setTabsValue] = useState(0);

  return (
    <Box
      py={2}
      sx={{
        // background: "#10102D",
      }}
    >
      <Container maxWidth="lg">
        <Box display="flex" justifyContent="center">
          <StyledTabs
            btnName={btnName}
            setTabsValue={setTabsValue}
            tabsValue={tabsValue}
          />
        </Box>
        {tabsValue === 0 ? <CreateLock /> : <CreateLock />}
      </Container>
    </Box>
  );
}
